import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import UserTransactions from "./UserTransactions";
import { useSelector } from "react-redux";
import UserWallets from "./Wallets/UserWallets";

function MyWallets() {

  const { userData: usrData } = useSelector((state) => ({
    userData: state.Profile,
  }));

  // const loadTabs = () => [
  //   {
  //     tabId: "1",
  //     navLinkName: "Withdrawals",
  //     component: <Withdrawal />,
  //   },
  //   {
  //     tabId: "2",
  //     navLinkName: "Deposits",
  //     component: <Deposit />,
  //   },
  // ];

  // const tabs = loadTabs().filter((item) => !item.hidden);
  // const [activeTab, setactiveTab] = useState(
  //   tabs.length > 0 ? tabs[0].tabId : ""
  // );
  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setactiveTab(tab);
  //   }
  // };
  // useEffect(() => {
  //   if (tabs.length > 0) {
  //     setactiveTab(tabs[0].tabId);
  //   }
  //   // if (tab) {
  //   //   setactiveTab(tab);
  //   // }
  // }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          My Wallets
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          {/* <h2> My Wallets</h2> */}
          <UserWallets/>
          <Col className="col-12">
            <Card>
              <CardBody>
                <UserTransactions customerId={usrData?._id}/>
              </CardBody>
            </Card>
          </Col>
          {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    {tabs.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => {
                              toggle(tabItem.tabId);
                            }}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    {tabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">{tabItem.component}</Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* <Withdrawal/>
          <Deposit/> */}
        </div>
      </div>
    </React.Fragment>
  );
}
export default MyWallets;