export const FETCH_COMPANY_BANK_ACCOUNT_REQUESTED = "FETCH_COMPANY_BANK_ACCOUNT_REQUESTED";
export const FETCH_COMPANY_BANK_ACCOUNT_SUCCESS = "FETCH_COMPANY_BANK_ACCOUNT_SUCCESS";
export const FETCH_COMPANY_BANK_ACCOUNT_FAIL = "FETCH_COMPANY_BANK_ACCOUNT_FAIL";

export const ADD_COMPANY_BANK_ACCOUNT_REQUESTED = "ADD_COMPANY_BANK_ACCOUNT_REQUESTED";
export const ADD_COMPANY_BANK_ACCOUNT_SUCCESS = "ADD_COMPANY_BANK_ACCOUNT_SUCCESS";
export const ADD_COMPANY_BANK_ACCOUNT_FAIL = "ADD_COMPANY_BANK_ACCOUNT_FAIL";
export const ADD_COMPANY_BANK_ACCOUNT_CLEAR = "ADD_COMPANY_BANK_ACCOUNT_CLEAR";

export const DELETE_COMPANY_BANK_ACCOUNT_REQUESTED = "DELETE_COMPANY_BANK_ACCOUNT_REQUESTED";
export const DELETE_COMPANY_BANK_ACCOUNT_SUCCESS = "DELETE_COMPANY_BANK_ACCOUNT_SUCCESS";
export const DELETE_COMPANY_BANK_ACCOUNT_FAIL = "DELETE_COMPANY_BANK_ACCOUNT_FAIL";

export const EDIT_COMPANY_BANK_ACCOUNT_REQUESTED = "EDIT_COMPANY_BANK_ACCOUNT_REQUESTED";
export const EDIT_COMPANY_BANK_ACCOUNT_SUCCESS = "EDIT_COMPANY_BANK_ACCOUNT_SUCCESS";
export const EDIT_COMPANY_BANK_ACCOUNT_FAIL = "EDIT_COMPANY_BANK_ACCOUNT_FAIL";
export const EDIT_COMPANY_BANK_ACCOUNT_CLEAR = "EDIT_COMPANY_BANK_ACCOUNT_CLEAR";

export const FETCH_USER_BANK_ACCOUNT = "FETCH_USER_BANK_ACCOUNT";
export const FETCH_USER_BANK_ACCOUNT_SUCCESS = "FETCH_USER_BANK_ACCOUNT_SUCCESS";
export const FETCH_USER_BANK_ACCOUNT_FAIL = "FETCH_USER_BANK_ACCOUNT_FAIL";