import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { startCase } from "lodash";
import { createTransactionPdf } from "apis/forexWithdrawal";

function ReceiptModal(props){
  const { content, open, onClose, id } = props;
  const baseUrl = process.env.REACT_APP_API_CRM_DOMAIN;
  const [dowanloadLoading, setIsDownloadLoading] = useState(false);
  async function downloadPdf() {
    try {
      setIsDownloadLoading(true);
      const result = await createTransactionPdf(id);
      setIsDownloadLoading(false);
      console.log("result", result);
      // const blob = await result?.result?.data?.blob();


      // const data = await result?.data?.json();

      // Convert the received buffer array back to a Uint8Array
      const bufferArray = new Uint8Array(result?.result?.data);

      // Convert the buffer array to a Blob object
      const blob = new Blob([bufferArray], { type: "application/pdf" });

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      // Set the download attribute with a default filename
      link.download = `certificate-${Date.now()}.pdf`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by removing the link
      link.remove();
    } catch (error) {
      setIsDownloadLoading(false);
      console.log(error);      
    }
  }
  return (
    <React.Fragment>
      <div>
        <Modal isOpen={open} toggle={onClose} centered={true} size={"lg"}>
          <ModalHeader toggle = {onClose} tag="h4">
            {props.t(content?.type === "image" ? "Receipt" : "Details")}
            { id && <button className="btn btn-primary mx-2" onClick={downloadPdf}>{
              dowanloadLoading ? <Spinner size={"sm"} /> : "Download"
            }</button>}
          </ModalHeader>
          <ModalBody>

            {
              content?.type === "image" ? (<>
                <img
                  src={`${baseUrl}/assets/${content.content}`}
                  alt="Receipt"
                  style={{ width: "100%" }}
                />
              </>

              ) 
                : 
                <>
                  {
                    Object.keys(content?.content2 || {})?.length > 0 && 
                    <>
                      <Row>
                        <strong>{content.Id ? "Id" : "" }</strong>
                        <p className="text-muted">{content?.Id}</p>
                        <h4 className="mb-3">{"Customer Details"}</h4>
                        {Object.keys(content?.content3 || {}).map((key) => (
                          <>
                            <div className={`col-${content?.content3?.[key]?.length > 15 ? 12 : 6}`}>
                              <p className="mb-1">
                                <strong>{startCase(key ?? "")}</strong>
                              </p>
                              <p className="text-muted">{content?.content3?.[key]}</p>
                            </div>
                          </>
                        ))}
                      </Row>
                      <hr/>
                    </>
                  }
                  <Row>
                    <strong>{content.Id ? "Id" : "" }</strong>
                    <p className="text-muted">{content?.Id}</p>
                    <h4 className="mb-3">{"Bank USDT Details"}</h4>
                    {Object.keys(content?.content || {}).map((key) => (
                      <>
                        <div className={`col-${content?.content?.[key]?.length > 15 ? 12 : 6}`}>
                          <p className="mb-1">
                            <strong>{startCase(key ?? "")}</strong>
                          </p>
                          <p className="text-muted">{content?.content?.[key]}</p>
                        </div>
                      </>
                    ))}
                  </Row>
                  {
                    Object.keys(content?.content2 || {})?.length > 0 && 
                    <>
                      <hr/>
                      <Row>
                        <h4 className="mb-3">{"Trading Account Details"}</h4>
                        {
                          Object.keys(content?.content2 || {})?.length > 0 ? <>
                            <strong>{content.Id ? "Id" : "" }</strong>
                            <p className="text-muted">{content?.Id}</p>
                            {Object.keys(content?.content2 || {}).map((key) => (
                              <>
                                <div className={`col-${content?.content2?.[key]?.length > 15 ? 12 : 6}`}>
                                  <p className="mb-1">
                                    <strong>{startCase(key ?? "")}</strong>
                                  </p>
                                  <p className="text-muted">{content?.content2?.[key]}</p>
                                </div>
                              </>
                            ))}
                          </>
                            :
                            <p className='d-flex align-items-center justify-content-center fw-bold'>There Is No Position Is Opened</p>
                        }
                      </Row>
                    </>
                  }

                  {/* <Row>
                    <strong>{content.Id ? "Id" : "" }</strong>
                    <p className="text-muted">{content?.Id}</p>
                    {Object.keys(forexWithdrawalFreeMargin || {}).map((key) => (
                      <>
                        <div className={`col-${forexWithdrawalFreeMargin?.[key]?.length > 15 ? 12 : 6}`}>
                          <p className="mb-1">
                            <strong>{startCase(key ?? "")}</strong>
                          </p>
                          <p className="text-muted">{forexWithdrawalFreeMargin?.[key]}</p>
                        </div>
                      </>
                    ))}
                  </Row> */}
                </>
            }
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReceiptModal);