import React, { useEffect, useState } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { Link } from "react-router-dom";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { useTranslation, withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import FeatherIcon from "feather-icons-react";
import { convertRebate, getUserWallets } from "apis/wallet";
import {
  AvField, AvForm, AvRadio, AvRadioGroup
} from "availity-reactstrap-validation";
import { showErrorNotification } from "store/actions";
import usePermissions from "routes/permissions";
import moment from "moment";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { getStatement } from "apis/client";
import StatementDeals from "pages/ClientDetail/Statement/StatementDeals";
import { fetchWalletStart } from "store/wallet/actions";
import { wallet } from "common/data";
import AddDepositForm from "./AddDepositForm";
import UserTransactions from "pages/Users/myWallets/UserTransactions";
import Withdrawal from "./Withdrawal";
import { fetchUserTransactionStart } from "store/transactions/withdrawal/action";
import { parse } from "path";
import ConversionEditModal from "pages/CurrencyRates/ConversionEditModal";
import { getUserTransactions } from "apis/withdrawal";

function UsersWallet(props) {
  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [convertModal, setConvertModal] = useState(false);
  const [walletIdModal, setWalletIdModal] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const depositReducer = useSelector((state) => state?.depositReducer);

  const { usersWalletPermissions } = usePermissions();

  console.log("USER WALLET PROPS: ", props);
  const handleConvert = (wallet) => {
    setSelectedWallet(wallet);
    setConvertModal(true);
  };

  const handleShowWalletId = (walletId) => {
    setSelectedWalletId(walletId);
    setWalletIdModal(true);
  };

  const columns = [
    {
      dataField: "walletId",
      text: props.t("Wallet Id"),
      formatter: (item) => item._id,
    },
    {
      dataField: "customerId",
      text: props.t("User"),
      formatter: (val) => {
        return (
          <div>
            {/* <Link
              to={{
                pathname: `/clients/${val?.belongsTo?._id}/profile`,
                state: { clientId: val.belongsTo }
              }}> */}
            <i className="no-italics fw-bold">{val.belongsTo ? `${captilazeFirstLetter(val.belongsTo.firstName)} ${captilazeFirstLetter(val.belongsTo.lastName)}` : ""}</i>
            {/* </Link> */}
          </div>
        );
      }
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (item) => (
        item.amount === " " ? "-" : parseFloat(item.amount)
      )
    },
    {
      dataField: "freezeAmount",
      text: props.t("Freeze Amount"),
      formatter: (item) => (
        item.freezeAmount === " " ? "N/A" : parseFloat(item.freezeAmount)
      )
    },
    {
      dataField: "pendingAmount",
      text: props.t("Pending Amount"),
      formatter: (item) => (
        item.pending === " " ? "N/A" : parseFloat(item?.pending || 0)
      )
    },
    // {
    //   dataField: "transferPendingRebate",
    //   text: props.t("Convert Pending Rebate"),
    //   formatter: (item) => (
    //     item?.isIb && ibWalletPermissions.update ? <Button color="primary" size="sm" onClick={() => handleConvert(item)}>
    //       {props.t("Convert")}
    //     </Button> : <span>-</span>
    //   ),
    // },
    {
      dataField: "showWalletId",
      text: props.t("Transactions"),
      formatter: (item) => (
        <FeatherIcon
          icon="eye"
          className="icon-lg"
          style={{ cursor: "pointer" }}
          onClick={() => handleShowWalletId(item?.belongsTo?._id)}
        />
      ),
    },
  ];
  const [sizePerPage, setSizePerPage] = useState(10);

  const initFilteredValues =  {
    customerId: "",
  };

  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };


  useEffect(() => {
    loadIbs(1, sizePerPage);
  }, [

    depositReducer.addLoading,
    sizePerPage,
    1,
    filteredValues,
  ]);

  const loadIbs = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getUserWallets({
        payload: {
          page,
          limit,
          // filteredValues,
          walletType:"users"
        }
      });
      if (response.status) {
        const { result } = response;
        setWallets(result.docs);
        setPagination(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">  
        <MetaTags>
          <title>
            Users Wallets
          </title>
        </MetaTags>
        <div className="container-fluid">
          <h2>Users Wallets</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">Users Wallets ({pagination.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => { loadIbs(1, sizePerPage) }}
                    />
                  </CardTitle>
                  {usersWalletPermissions.update && (
                    <AddDepositForm />
                  )}
                  {/* <UsersWalletFilters filterChangeHandler={filterChangeHandler} filteredValues={filteredValues} /> */}
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading && wallets.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...pagination}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadIbs}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {convertModal && <ConvertModal
          open={convertModal}
          wallet={selectedWallet}
          onClose={() => {
            setConvertModal(false);
            loadIbs(1, sizePerPage);
          }}
        />}
        {walletIdModal && (
          <IbStatementModal
            open={walletIdModal}
            onClose={() => {
              setWalletIdModal(false);
            }}
            customerId={selectedWalletId}
          />
        )}
      </div>
    </React.Fragment>
  );
}

function ConvertModal({ open, wallet, onClose }) {
  const [loading, setLoading] = useState(false);
  const [convertType, setConvertType] = useState("pending");
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await convertRebate({
        walletId: wallet._id,
        amount: amount,
        type: convertType,
      });
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Convert Wallet</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvField
            name="walletId"
            type="hidden"
            value={wallet._id}
          />
          <AvField
            name="pendingBalance"
            label="Pending Balance"
            type="number"
            value={wallet?.pending || "0"}
            disabled
          />
          <AvField
            name="availableBalance"
            label="Available Balance"
            type="number"
            value={wallet?.amount || "0"}
            disabled
          />
          <div className="d-flex gap-3">
            <Label>Convert Type</Label>
            <AvRadioGroup
              name="convertType"
              label=""
              value={convertType}
              onChange={(e) => setConvertType(e.target.value)}
              required
            >
              <AvRadio label="Convert Pending to Available" value="pending" />
              <AvRadio label="Convert Available to Pending" value="useable" />
            </AvRadioGroup>
          </div>
          <AvField
            name="amount"
            label="Amount to Convert"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an amount"
              },
              min: {
                value: 0,
                errorMessage: "Amount must be greater than 0"
              },
              max: {
                value: convertType === "pending" ? wallet?.pending : wallet?.amount,
                errorMessage: `Amount exceeds available ${convertType === "pending" ? "pending" : "available"} balance`
              }
            }}
          />
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Converting..." : "Convert"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

function IbStatementModal({ open, onClose, customerId }) {
  const { t } = useTranslation();

  const { userData: usrData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const [sizePerPage, setSizePerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));
  const [selectedPlatform, setSelectedPlatform] = useState("MT5");
  const [statement, setStatement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentLogin, setCurrentLogin] = useState(null);
  const [allTransactions, setAllTransactions] = useState([]);


  const dispatch = useDispatch();
  const columns = [
    {
      dataField: "name",
      text: t("Name"),
      formatter: (obj) => {
        return (
          <Link href={`/clients/${(obj.client || {})._id}/profile`}>
            <strong className="text-capitalize">{`${(obj.client || {}).firstName} ${(obj.client || {}).lastName}`}</strong>
          </Link>
        );
      }
    },
    {
      dataField: "clientLogin",
      text: t("Client Login"),
      formatter: (obj) => {
        return (
          <div onClick={() => setCurrentLogin(obj.clientLogin)}>
            <strong className="text-capitalize">{obj.clientLogin}</strong>
          </div>
        );
      }
    },
    {
      dataField: "lotsOpened",
      text: t("Lots Opened"),
      formatter: (val) => (val ? (parseFloat(val.lotsOpened) / 10000).toFixed(2) : "-"),
    },
    {
      dataField: "commission",
      text: t("Commission"),
      formatter: (val) => (val ? parseFloat(val.commission)?.toFixed(2) : "-"),
    },
    {
      dataField: "lotsClosed",
      text: t("Lots Closed"),
      formatter: (val) => (val ? (parseFloat(val.lotsClosed) / 10000).toFixed(2) : "-"),
    },
    {
      dataField: "rebate",
      text: t("Rebate"),
      formatter: (val) => parseFloat(val.rebate)?.toFixed(2),
    },
    {
      dataField: "profitShare",
      text: t("Profit Share"),
      formatter: (val) => parseFloat(val.profitShare)?.toFixed(2),
    },
  ];


  const handleDateTo = (e) => setDateTo(e.target.value);
  const state = useSelector((state) => state);
  const [totalDocs, setTotalDocs] = useState(
    () => state.withdrawalReducer?.userTransactions?.totalDocs
  );


  const platformOptions = [
    {
      label: t("MT5"),
      value: "MT5",
    },
    {
      label: t("MT4"),
      value: "MT4",
    },
  ];

  const loadWithdrawals = (page, limit) => {
    console.log("FROM: ", dateFrom);
    console.log("TO: ", dateTo);
    dispatch(
      fetchUserTransactionStart({
        page,
        limit,
        filteredValues: {
          filterDate: {
            fromDate: dateFrom,
            toDate: dateTo,
          }
        },
        customerId: customerId ? customerId : usrData?._id,
      })
    );
  };

  const getAllTransactions = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getUserTransactions({
        payload: {
          limit: parseInt(limit),
          page: parseInt(page),
          filteredValues: {
            filterDate: {
              fromDate: dateFrom,
              toDate: dateTo,
            },
          },
          customerId: customerId ? customerId : usrData?._id,
        },
      });
      if (response.status) {
        setAllTransactions(response.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("STARTING: ");
    getAllTransactions(
      1,
      state.withdrawalReducer?.userTransactions?.totalDocs || 999999999999
    );
  }, []);

  // const loadTransactions = async (page = 1, limit = 10) => {
  //   setLoading(true);
  //   try {
  //     const response = await getStatement({
  //       payload: {
  //         limit: parseInt(limit),
  //         page: parseInt(page),
  //         platform: selectedPlatform,
  //         customerId,
  //         dateFrom: dateFrom,
  //         dateTo: dateTo,
  //       }
  //     });

  //     if (response.status) {
  //       setStatement(response.result);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    loadWithdrawals(1, sizePerPage);
  }, [sizePerPage, selectedPlatform]);

  // useEffect(() => {
  //   loadTransactions(1, 10);
  // }, []);

  return (
    <Modal isOpen={open} toggle={onClose} size="xl">
      <ModalHeader toggle={onClose}>User Transaction</ModalHeader>
      <ModalBody className="px-4">
        {/* <hr className="my-4" /> */}
        <Withdrawal
          customerId={customerId ? customerId : usrData?._id}
          t={t}
          //dateFrom={dateFrom}
          //handleDateFrom={handleDateFrom}
          //dateTo={dateTo}
          //handleDateTo={handleDateTo}
          loading={loading}
          setLoading={setLoading}
          transactions={allTransactions}
          totalTransactionsDocs={totalDocs}
          getAllTransactions={getAllTransactions}
        />
        {/* <UserTransactions customerId={customerId ? customerId : usrData?._id}/> */}
        {/* <hr className="my-4" />
        <StatementDeals
          dateFrom={dateFrom}
          dateTo={dateTo}
          currentLogin={currentLogin}
          selectedPlatform={selectedPlatform}
        /> */}
      </ModalBody>
    </Modal>
  );
}

export default (withTranslation()(UsersWallet));