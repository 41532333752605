import React, { 
  useState, useEffect, useCallback 
} from "react";
import {
  useDispatch,
  connect,
  useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  Alert,
} from "reactstrap";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { makeWithdrawalStart } from "store/transactions/withdrawal/action";
import { fetchGatewaysOfWithdrawalsStart } from "store/gateway/action";
import { 
  fetchWalletStart, clearWallets, fetchClientWallets 
} from "store/wallet/list/action";
import { addBankAccount, fetchUserBankAccount } from "store/companyBankAccount/actions";
import { fetchClientsStart } from "store/client/actions";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Loader from "components/Common/Loader";
import * as clientApi from "apis/client";
import { fetchConversionRates } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import AddBankAccountModal from "pages/CompanyBanks/AddBankAccountModal";
import ShowUsdtMethodModal from "pages/Users/myMethods/modals/ShowUsdtMethodModal";
import ShowDetails from "pages/Users/myMethods/modals/ShowDetails";
import _default from "react-bootstrap/esm/ListGroup";

function WithdrawForm(props){
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [fees, setFees] = useState(0);
  const [toCurrency, setToCurrency] = useState("");

  const { 
    loading,
    conversionRates,
    pagination,
    clearingCounter,
  } = useSelector((state) => state.conversionRatesReducer);

  useEffect(()=>{
    loadConversionRates(1, 100000);
  }, [clearingCounter]);

  const loadConversionRates = (page, limit) => {
    dispatch(fetchConversionRates({
      page,
      limit
    }));
  };
  useEffect(()=>{
    const finalAmount = toCurrency 
      ? ((amount - (fees || 0)) / parseFloat(
        conversionRates?.find((c) => 
          (gateway === "BANK" ? c.baseCurrency === "USD" : c.baseCurrency === "USDT") 
          && c.targetCurrency === toCurrency
        )?.value || 1
      )).toFixed(2)
      : amount - (fees || 0);
    setConvertedAmount(isNaN(finalAmount) ? amount : finalAmount );
  }, [fees, amount, toCurrency]);


  const dispatch = useDispatch();
  const [withdrawalModal, setWithdrawalModal] = useState(false);
  //const [chooseMethod, setChooseMethod] = useState(false);
  const [openBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [openUSDTModal, setOpenUSDTModal] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [gateway, setGateway] = useState("");
  const [type, setType] = useState("LIVE");
  const [gatewayError, setGatewayError] = useState(false);
  const { create } = props.withdrawalsPermissions;
  const [bankAccounts, setBankAccounts] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState("");
  const [withdrawalMethodValue, setWithdrawalMethodValue] = useState(null);
  const [filteredBankAccounts, setFilteredBankAccounts] = useState([]);
  const [showBankModal, setShowBankModal] = useState(false);
  const [showUsdtModal, setShowUsdtModal] = useState(false);
  const [toCurrencyValue, setToCurrencyValue] = useState(null);
  const [exchangeError, setExchangeError] = useState(false);


  const  toggleUsdtMethod = () => {
    setShowUsdtModal(!showUsdtModal);
  };

  const  toggleBankAccount = () => {
    setShowBankModal(!showBankModal);
  };

  const { userData: usrData } = useSelector((state) => ({
    userData: state.Profile,
  }));


  const userBankAccount = useSelector((state) => state.banks.userBankAccount);

  useEffect(() => {
    dispatch(fetchUserBankAccount());
  }, [dispatch]);

  useEffect(() => {
    if (userBankAccount && userBankAccount.docs) {
      setBankAccounts(userBankAccount.docs);
    }
  }, [userBankAccount]);

  useEffect(() => {
    console.log("Current gateway:", gateway);
    // console.log("All bank accounts:", bankAccounts);
    console.log("this witdraw", withdrawalMethod);
    if (gateway && bankAccounts.length > 0) {
      const filtered = bankAccounts.filter((bank) => bank.type === gateway);
      console.log("this is filterd", filtered);
      setFilteredBankAccounts(filtered);
      setWithdrawalMethod("");
      console.log("Filtered bank accounts:", filtered);
    } else {
      setFilteredBankAccounts([]);
    }
  }, [gateway, bankAccounts]);
  const handleGatewayChange = (e) => {
    setGateway(e.value);   
    if (e.value === "USDT") {
      setFilteredBankAccounts([]);
    }
  };

  const handleWithdraw = (event, values) => {
    event.preventDefault();
    dispatch(makeWithdrawalStart({
      // customerId:selectedClient,
      walletType: "users",
      method: JSON.stringify(bankAccounts.filter(bank => bank._id === withdrawalMethod)),
      customerId: usrData?._id,
      walletId: selectedWalletId,
      gateway,
      amount: Number(convertedAmount),
      convertedFromAmount: Number(amount),
      fromCurrency: toCurrency,
      ...values
    }));
    setSearchInput("");
    dispatch(clearWallets());
    setGateway("");
    setSelectedWalletId(null);
  };

  useEffect(() => {
    dispatch(fetchClientWallets({
      belongsTo: usrData?._id
    }));
  }, []);

  useEffect(() => {
    setGateway("");
  }, [withdrawalModal]);

  //   useEffect(() => {
  //   if (!gateway || (gateway !== "USDT" && gateway !== "BANK")) {
  //     setGatewayError(true);
  //   } else {
  //     setGatewayError(false);
  //   }
  // }, [gateway]);

  useEffect(() => {
    //console.log("gateway:", gateway);
    //console.log("filtered bankAccounts:", bankAccounts.filter((bank) => bank.type === gateway && bank.bankType === "users"));
  }, [gateway, bankAccounts]);

  const selectType = (type)=>{
    setType(type);
    if (selectedClient.length > 0)
      dispatch(fetchWalletStart({
        belongsTo:selectedClient,
        customerId:selectedClient,
      }));
  };
  const toggleAddModal = () => {
    setWithdrawalMethod("");
    setGateway("");
    setConvertedAmount(0);
    setAmount(0);
    setGatewayError(false);
    setWithdrawalModal(!withdrawalModal);
    setSelectedWalletId(null);
  };
  const toggleChoosePayment = () => {
    props.setChooseMethod(!props.chooseMethod);
    setGatewayError(false);
  };
  const toggleBankAccountModal = () => {
    console.log("Toggling bank account modal");
    setOpenBankAccountModal(!openBankAccountModal);
    props.setChooseMethod(false);
    setGatewayError(false);
  };
  const toggleUsdtMethodModal = () => {
    setOpenUSDTModal(!openUSDTModal);
    props.setChooseMethod(false);
    setGatewayError(false);
  };

  useEffect(()=>{
    setWithdrawalMethodValue(null);
    setToCurrencyValue(null);
    dispatch(fetchGatewaysOfWithdrawalsStart());
  }, [gateway]);


  useEffect(()=>{
    dispatch(fetchClientsStart({
      page: 1,
      // type
    }));

    if (searchInput.length >= 3){
      dispatch(fetchClientsStart({
        searchText:searchInput,
        limit:props.totalDocs,
        type
      }));
    }
  }, [searchInput, type, withdrawalModal]);

  useEffect(() => {
    if (props.withdrawalModalClear && withdrawalModal){
      setWithdrawalModal(false);
      setGateway("");
    }
  }, [props.withdrawalModalClear]);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      clientApi.getClients({
        payload: {
          searchText: inputValue,
          type: "LIVE"
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((item) => (
          {
            label : `${item.firstName} ${item.lastName}`,
            value : {
              name: `${item.firstName} ${item.lastName}`,
              id: `${item._id}`
            }
          }
        )));
      });
    }, 1000), []
  );

  // debounce function handlers
  const fetchClientWalletDebounceHandler = useCallback(
    debounce((id) => setSelectedWalletId(id), 1000), []
  );

  const addBankAccountHandler = (e, values) => {
    dispatch(addBankAccount({
      ...values,
      customerId: usrData._id,
      type: "BANK",
      bankType: "users",
    }));
    //toggleBankAccountModal();
  };
  const addUsdtMethodHandler = (e, values) => {
    dispatch(addBankAccount({
      ...values,
      customerId: usrData._id,
      type: "USDT",
      bankType: "users"
    }));
    toggleUsdtMethodModal();
  };
  return (
    <React.Fragment>
      <>
        {
          <Link
            to="#"
            className={`btn btn-primary ${!create ? "d-none" : ""} me-1`}
            onClick={toggleAddModal}
          >
            <i className="bx bx-plus me-1"></i> {props.t("Add New Withdraw")}
          </Link>
          /* 
        <Link
          to="#"
          className={`btn btn-primary ${!create ? "d-none" : ""} me-1`}
          onClick={toggleChoosePayment}
        >
          <i className=""></i> {props.t("Add New Bank Account/USDT")}
        </Link>
        <Link
          to="/my-methods"
          className={`btn btn-primary ${!create ? "d-none" : ""} me-1`}
          //onClick={toggleChoosePayment}
        >
          <i className=""></i> {props.t("Show Withdrawal Methods")}
        </Link> */}
      </>

      <Modal isOpen={withdrawalModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Make Withdraw")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="px-4 py-2"
            onValidSubmit={(e, v) => {
              delete v.client;
              delete v.wallet;
              delete v.gateway;
              delete v.type;
              delete v.amount;
              delete v.toCurrency;
              // v.amount = Number(v.amount);
              if (!gateway) {
                setGatewayError(true);
              }
              if (!toCurrencyValue) {
                setExchangeError(true);
              }
              
              if (gateway && toCurrencyValue) {
                handleWithdraw(e, v);
              }
            }}
          >
            <Row className="mb-3">
              {/* <Col md="12">
                <div>
                  <Label>{props.t("Client")}</Label>
                  <div>
                    <AsyncSelect
                      isClearable
                      placeholder = {props.t("Choose A Client")}
                      defaultOptions={props.loading ? [] : props.clients.map((item) => (
                        {
                          label : `${item.firstName} ${item.lastName}`,
                          value : {
                            name: `${item.firstName} ${item.lastName}`,
                            id: `${item._id}`
                          }
                        }
                      ))}
                      classNamePrefix="select2-selection"
                      loadOptions={debouncedChangeHandler}
                      onChange={(e) => { 
                        if (e && e.value && e.value.id) {
                          setSelectedClient(e.value.id);
                        }
                      }}
                      isRequired={true}
                      isSearchable={true}
                      backspaceRemoves={true}
                      name="clientId"
                    />
                    <AvField
                      name="client"
                      type="text"
                      errorMessage={props.t("Choose A Client")}
                      validate={{ required: { value: true } }}
                      value={selectedClient}
                      style={{
                        opacity: 0,
                        height: 0,
                        width: 0,
                        margin: -10
                      }}
                    />
                  </div>
                </div>
              </Col> */}

              {/* <Col md="6">
                <Label>{props.t("Type")}</Label>
                <div>
                  <Select 
                    defaultValue={{
                      label:"Live",
                      value:"LIVE" 
                    }}
                    onChange={(e) => {
                      selectType(e.value);   
                    }}
                    options={[{
                      label:"Live",
                      value:"LIVE" 
                    },
                    {
                      label:"Demo",
                      value:"DEMO"
                    }]}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose Withdrawal Type")}
                  />
                  <AvField
                    name="type"
                    type="text"
                    errorMessage={props.t("Choose Withdrawal Type")}
                    validate={{ required: { value: true } }}
                    value={selectType}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col> */}
            </Row>

            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Wallet")}</Label>
                <div>
                  <Select
                    onChange={(e) => {
                      fetchClientWalletDebounceHandler(e.value.id);
                    }}
                    isSearchable={true}
                    options={props.wallets.map((wallet) => ({
                      label: `${wallet.asset}-(Balance ${wallet.amount} ${wallet.asset})`,
                      value: {
                        id: `${wallet._id}`,
                      },
                    }))}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose A Wallet")}
                  />
                  <AvField
                    name="wallet"
                    type="text"
                    errorMessage={props.t("Choose A Wallet")}
                    validate={{ required: { value: true } }}
                    value={selectedWalletId}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10,
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className="mb-3">
              <Label>{props.t("Gateway")}</Label>
              <div>
                <Select
                  value={gateway ? { 
                    value: gateway, 
                    label: gateway 
                  } : null}
                  onChange={(e) => {
                    handleGatewayChange(e);
                    setGatewayError(false);
                  }}
                  isSearchable={true}
                  options={[
                    { 
                      label: "Bank", 
                      value: "BANK" 
                    },
                    { 
                      label: "USDT", 
                      value: "USDT" 
                    },
                  ]}
                  classNamePrefix="select2-selection"
                  placeholder={props.t("Choose A Gateway")}
                />
                <AvField
                  name="gateway"
                  value={gateway}
                  style={{ display: "none" }}
                  validate={{ 
                    required: { 
                      value: true, 
                      errorMessage: props.t("Choose Valid Gateway") 
                    }
                  }
                  }
                />
              </div>
            </div>
            {/* <div className="mb-3">
              <Label>{props.t("Withdrawal Method")}</Label>
              <div>
                <Select
                  onChange={(e) => {
                    setWithdrawalMethods(e.value.method);
                  }}
                  isSearchable={true}
                  options={bankAccounts.filter((bank) => bank.type === gateway)}
                  classNamePrefix="select2-selection"
                  placeholder={props.t("Choose A Withdrawal Method")}
                />
                <AvField
                  name="method"
                  type="text"
                  errorMessage={props.t("Choose A Withdrawal Method")}
                  validate={{ required: { value: true } }}
                  value={withdrawalMethods}
                  style={{
                    opacity: 0,
                    height: 0,
                    width: 0,
                    margin: -10,
                  }}
                />
                {gatewayError && (
                  <small className="text-danger">
                    {props.t("Choose Valid Withdrawal Method")}
                  </small>
                )}
              </div>
            </div> */}
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <Label>{props.t("Withdrawal Method")}</Label>{" "}
                {withdrawalMethod && <Button
                  className="fs-8 p-2 mb-2"
                  disabled={props.addLoading}
                  onClick={() => {
                    if (withdrawalMethod === "") return;
                    const selectedBank = bankAccounts.filter(
                      (bank) => bank._id === withdrawalMethod
                    )[0];
                    if (selectedBank.type === "USDT") {
                      toggleUsdtMethod();
                    } else {
                      toggleBankAccount();
                    }
                  }}
                  color="primary"
                >
                  {props.t("Show Details")}
                </Button>}
              </div>
              <div>
                <Select
                  value={withdrawalMethodValue}
                  onChange={(e) => {
                    setWithdrawalMethodValue(e);
                    setWithdrawalMethod(e.value);
                    console.log("from select", e);
                  }}
                  isSearchable={true}
                  options={filteredBankAccounts.map((bank) => ({
                    label: gateway === "BANK" ? bank.bankName : bank.address,
                    value: bank.id,
                  }))}
                  placeholder={props.t("Choose A Withdrawal Method")}
                  // value={filteredBankAccounts[0]}
                />
                <AvField
                  name="method"
                  type="text"
                  errorMessage={props.t("Choose A Withdrawal Method")}
                  validate={{ required: { value: true } }}
                  value={withdrawalMethod}
                  style={{
                    opacity: 0,
                    height: 0,
                    width: 0,
                    margin: -10,
                  }}
                />
                {/* {gatewayError && (
                  <small className="text-danger">
                    {props.t("Choose Valid Withdrawal Method")}
                  </small>
                )} */}
              </div>
            </div>
            <div className="mb-3">
              <Label>{props.t("Exchange Rate")}</Label>
              <Select
                name="toCurrency"
                placeholder={props.t("Select To Exchange Rate")}
                options={conversionRates
                  ?.filter((rateItem) => {
                    if (gateway === "USDT") return rateItem?.baseCurrency === "USDT";
                    if (gateway === "BANK") return rateItem?.baseCurrency === "USD";
                    return false;
                  })
                  ?.map((rate) => ({
                    value: rate?.targetCurrency,
                    label: rate?.targetCurrency,
                  }))}
                value={toCurrencyValue}
                onChange={(e) => {
                  setToCurrency(e.value);
                  setToCurrencyValue(e);
                  setExchangeError(false);
                }}
              />
              <AvField
                name="toCurrency"
                value={toCurrencyValue?.value || ""}
                style={{ display: "none" }}
                validate={{ 
                  required: 
                  { 
                    value: true, 
                    errorMessage: props.t("Exchange Rate is required") 
                  }
                }
                }
              />
              {exchangeError && (
                <small className="text-danger">
                  {props.t("Exchange Rate is required")}
                </small>
              )}
            </div>
            <div className="mb-3">
              <AvField
                name="amount"
                label={props.t(`Amount ${toCurrency}`)}
                placeholder={props.t("Enter An Amount")}
                type="number"
                min="1"
                onChange={(e) => setAmount(e.target.value)}
                errorMessage={props.t("Enter An Amount")}
                validate={{
                  required: { value: true },
                }}
              />
            </div>
            {
              <>
                <Alert color="warning">
                  The Converted Amount Is : {convertedAmount} USD
                </Alert>
              </>
            }

            <div className="mb-3">
              <AvField
                name="note"
                label={props.t("Note")}
                placeholder={props.t("Enter Note")}
                type="text"
              />
            </div>

            <div className="text-center mt-3 p-2">
              {props.addLoading ? (
                <Loader />
              ) : (
                <>
                  <Button
                    disabled={props.addLoading}
                    type="submit"
                    color="primary"
                    style={{ marginRight: "10px" }}
                  >
                    {props.t("Add")}
                  </Button>
                </>
              )}
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.t(props.addWithdrawalErrorDetails)}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={props.chooseMethod} toggle={toggleChoosePayment} centered>
        <ModalHeader toggle={toggleChoosePayment} tag="h4">
          Add Bank Account / USDT Method
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p className="mb-4">Choose your preferred withdrawal method:</p>
            <div>
              <Button
                color="primary"
                className="w-100 mb-3 py-2" // Add mb-3 for spacing
                onClick={toggleBankAccountModal}
              >
                Add Bank Account
              </Button>
              <Button
                color="success"
                className="w-100 py-2"
                onClick={toggleUsdtMethodModal}
              >
                Add USDT Method
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openBankAccountModal}
        toggle={toggleBankAccountModal}
        centered={true}
      >
        <ModalHeader toggle={toggleBankAccountModal} tag="h4">
          {props.t("Add new bank account")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              addBankAccountHandler(e, v);
              toggleBankAccountModal();
            }}
          >
            <div className="mb-3">
              <AvField
                name="accountHolderName"
                label={props.t("Beneficiary Name")}
                placeholder={props.t("Enter Beneficiary Name")}
                type="text"
                errorMessage={props.t("Enter Beneficiary Name")}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="bankName"
                label={props.t("Bank name")}
                placeholder={props.t("Enter Bank Name")}
                type="text"
                errorMessage={props.t("Enter Bank Name")}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="accountNumber"
                label={props.t("Account number")}
                placeholder={props.t("Enter Account Number")}
                type="text"
                onKeyPress={(e) => {
                  if (!isNaN(e.key) && e.target.value.length > 0) {
                    return true;
                  }
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                validate={{
                  pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "testing error message",
                  },
                }}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="swiftCode"
                label={props.t("Swift code/IFSC CODE")}
                placeholder={props.t("Enter Swift/IFSC Code")}
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Enter swift code",
                  },
                  // minLength: {
                  //   value: 8,
                  //   errorMessage: "Swift code must consist of 8 characters"
                  // },
                  // maxLength: {
                  //   value: 8,
                  //   errorMessage: "Swift code must consist of 8 characters"
                  // },
                  // pattern: {
                  //   value: "/^[A-Z]*$/",
                  //   errorMessage: "Swift code can only contain uppercase characters"
                  // }
                }}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="address"
                label={props.t("Address")}
                placeholder={props.t("Enter Address")}
                type="text"
                errorMessage={props.t("enter Address")}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="iban"
                label={props.t("IBAN")}
                placeholder={props.t("Enter IBAN (If required)")}
                type="text"
                default=""
                // validate={
                //   {
                //     required: {
                //       value: true,
                //       errorMessage: "Enter IBAN"
                //     },
                //     pattern: {
                //       value: "^[A-Z][A-Z]",
                //       errorMessage: "IBAN must start with two uppercase charecters"
                //     },
                //     minLength: {
                //       value: 13,
                //       errorMessage: "IBAN must contain at least 13 characters"
                //     }
                // }
                // }
              />
            </div>

            <div className="mb-3">
              <AvField
                name="currency"
                label={props.t("Currency")}
                placeholder={props.t("Enter Currency")}
                type="text"
                errorMessage={props.t("Enter Currency")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="text-center pt-3 p-2">
              <Button disabled={props.addLoading} type="submit" color="primary">
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.addError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.t(JSON.stringify(props.addErrorDetails))}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openUSDTModal}
        toggle={toggleUsdtMethodModal}
        centered={true}
      >
        <ModalHeader toggle={toggleUsdtMethodModal} tag="h4">
          {props.t("Add USDT Address")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              //addUsdtAddressHandler(e, v); // Replace with your handler
              addUsdtMethodHandler(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="address"
                label={props.t("USDT Address")}
                placeholder={props.t("Enter USDT Address")}
                type="text"
                errorMessage={props.t("Enter a valid USDT Address")}
                /*validate={{
                  required: { value: true },
                  pattern: {
                    value: "^(0x)?[0-9a-fA-F]{40}$", // Example regex for ETH-based USDT
                    errorMessage: props.t("Invalid USDT address"),
                  },
                }}*/
              />
            </div>

            <div className="mb-3">
              <AvField
                name="network"
                label={props.t("Network")}
                type="select"
                errorMessage={props.t("Select a Network")}
                validate={{ required: { value: true } }}
              >
                <option value="">{props.t("Select Network")}</option>
                <option value="TRC20">{props.t("TRC20")}</option>
              </AvField>
            </div>

            <div className="text-center pt-3 p-2">
              <Button disabled={props.addLoading} type="submit" color="primary">
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.addError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.t(JSON.stringify(props.addErrorDetails))}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
      <ShowUsdtMethodModal
        isOpen={showUsdtModal}
        toggleOpen={toggleUsdtMethod}
        BankAccountData={
          bankAccounts.filter((item) => item._id === withdrawalMethod)[0]
        }
      />
      <ShowDetails
        isOpen={showBankModal}
        toggleOpen={toggleBankAccount}
        BankAccountData={
          bankAccounts.filter((item) => item._id === withdrawalMethod)[0]
        }
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  gateways:state.gatewayReducer.gateways || [],
  error: state.withdrawalReducer.addWithdrawalError,
  addWithdrawalErrorDetails: state.withdrawalReducer.addWithdrawalErrorDetails,
  withdrawResponseMessage:state.withdrawalReducer.withdrawResponseMessage,
  withdrawalModalClear:state.withdrawalReducer.withdrawalModalClear,
  clients:state.clientReducer.clients || [],
  wallets:state.walletReducer.wallet.docs || [],
  withdrawalsPermissions: state.Profile.withdrawalsPermissions || {}, 
  disableWithdrawalButton : state.withdrawalReducer.disableWithdrawalButton,
  totalDocs:state.clientReducer.totalDocs,
  loading:state.clientReducer.loading,
  addLoading: state.withdrawalReducer.addLoading
});
export default connect(mapStateToProps, null)(withTranslation()(WithdrawForm));