import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import classNames from "classnames";
import Withdrawal from "./Wallets/Withdrawal";
import Deposit from "./Wallets/Deposit";

function UserTransactions({ customerId }) {

  const loadTabs = () => [
    {
      tabId: "1",
      navLinkName: "Withdrawals",
      component: <Withdrawal customerId={customerId}/>,
    },
    {
      tabId: "2",
      navLinkName: "Deposits",
      component: <Deposit customerId={customerId}/>,
    },
  ];

  const tabs = loadTabs().filter((item) => !item.hidden);
  const [activeTab, setactiveTab] = useState(
    tabs.length > 0 ? tabs[0].tabId : ""
  );
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  useEffect(() => {
    if (tabs.length > 0) {
      setactiveTab(tabs[0].tabId);
    }
    // if (tab) {
    //   setactiveTab(tab);
    // }
  }, []);

  return (
    <Row>
      <Nav tabs>
        {tabs.map((tabItem) => (
          <>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeTab === tabItem.tabId,
                })}
                onClick={() => {
                  toggle(tabItem.tabId);
                }}
              >
                {tabItem.navLinkName}
              </NavLink>
            </NavItem>
          </>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted">
        {tabs.map((tabItem) => (
          <>
            <TabPane tabId={tabItem.tabId}>
              <Row>
                <Col sm="12">{tabItem.component}</Col>
              </Row>
            </TabPane>
          </>
        ))}
      </TabContent>
    </Row>
  );
}
export default UserTransactions;