import {
  useDispatch, connect, useSelector 
} from "react-redux";
import { withTranslation } from "react-i18next";
import {
  showErrorNotification, showSuccessNotification, toggleCurrentModal 
} from "store/actions";
import {
  Button, Col, Container, Row 
} from "reactstrap";
import CardWrapper from "components/Common/CardWrapper";
import { MetaTags } from "react-meta-tags";
import {
  deleteBankAccount, fetchBankAccount, fetchUserBankAccount 
} from "store/companyBankAccount/actions";
import DeleteModal from "components/Common/DeleteModal";
import ShowDetails from "./modals/ShowDetails";
import ShowUsdtMethodModal from "./modals/ShowUsdtMethodModal";
import { useEffect, useState } from "react";

function BankAccounts(props) {
  const dispatch = useDispatch();
  const { t } = props;
  const [showBankModal, setShowBankModal] = useState(false);
  const [updateBankModal, setUpdateBankModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showUsdtModal, setShowUsdtModal] = useState(false);
  const [updateUsdtModal, setUpdateUsdtModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [selectedBankId, setSelectedBankId] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);

  const userBankAccount = useSelector((state) => state.banks.userBankAccount);

  useEffect(() => {
    dispatch(fetchUserBankAccount());
  }, [dispatch]);

  useEffect(() => {
    if (userBankAccount && userBankAccount.docs) {
      setBankAccounts(userBankAccount.docs);
    }
  }, [userBankAccount]);

  console.log("Bank Accounts: ", bankAccounts);
  //console.log("Selected Id: ", selectedBank);
  const handelShow = (accountDetails) => {
    setSelectedBank(accountDetails);
    setShowBankModal(true);
  };
  //console.log(bankAccounts);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  //   const permissions = userData?.roleId?.permissions;
  //   const { clientBankAccounts } = permissions;


  const handelUpdate = (accountDetails) => {
    setSelectedBank(accountDetails);
    setUpdateBankModal(true);
  };
  /*
  const handelDelete = () => {
    console.log(selectedBankId);
    dispatch(deleteBankAccount(selectedBankId))
      .then(() => {
        dispatch(
          showSuccessNotification("Bank Account Deleted successfully !!!")
        );
        dispatch(fetchUserBankAccount({
          limit: 100,
          page: 1 
        }));
        setDeleteModal(false);
      })
      .catch((e) => {
        dispatch(showErrorNotification(e.toString()));
      });
  };
*/
  const handelDelete = async () => {
    try {
      console.log(selectedBankId);
      await dispatch(deleteBankAccount(selectedBankId));
      dispatch(showSuccessNotification("Bank Account Deleted successfully !!!"));
      setDeleteModal(false);
      await dispatch(fetchUserBankAccount({
        limit: 100,
        page: 1 
      }));
    } catch (e) {
      dispatch(showErrorNotification(e.toString()));
    }
  };
  // we need to fetch data from the server and store it in the redux store and we need to use it here for banks accounts and log it


  const state = useSelector((state) => state);
  console.log(state);


  return (
    <div className="page-content">
      <MetaTags>
        <title>{t("Bank Accounts/USDT")}</title>
      </MetaTags>
      <Container>
        <div className="wallet-page h-100 ">
          <div className="d-flex justify-content-between m-4">
            <h1
              className="color-primary"
              style={{
                zIndex: 2,
              }}
            >
              {t("Bank Accounts/USDT Methods")}
            </h1>
            {/* <Button
              type="button"
              className="color-bg-btn border-0 m-2 p-2 btn-sm w-md"
              style={{
                zIndex: 2,
              }}
              onClick={() => {
                dispatch(toggleCurrentModal("SelectWithdrawalWayModal"));
              }}
            >
              {t("Add Bank Account/USDT")}
            </Button> */}
          </div>
          {bankAccounts?.length < 1 && (
            <CardWrapper className="mb-3 total-balance p-4 shadow glass-card">
              <Row className="align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-center">
                  <h4 className="color-primary">{"No bank accounts"}</h4>
                </div>
              </Row>
            </CardWrapper>
          )}
          {bankAccounts?.map((bankAccount, index) => (
            <CardWrapper
              key={index}
              className="mb-3 total-balance p-4 glass-card shadow"
            >
              <Row className="align-items-center justify-content-between">
                <Col lg={2}>
                  <div className="wallets__total">
                    {bankAccount.type === "USDT" ? (
                      <>
                        <div className="wallets__title h6 text-nowrap">
                          {bankAccount.network}{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#ff6600",
                              marginLeft: "15px",
                            }}
                          >
                            {bankAccount.type}
                          </span>
                        </div>
                        <div className="total-balance-container">
                          <div className="balance-price">
                            {bankAccount.address}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wallets__title h6 text-nowrap">
                          {bankAccount.bankName}{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#ff6600",
                              marginLeft: "15px",
                            }}
                          >
                            {bankAccount.type}
                          </span>
                        </div>
                        <div className="total-balance-container">
                          <div className="h4 text-nowrap">
                            {bankAccount.accountHolderName}
                          </div>
                        </div>
                        <div className="balance-price">
                          {bankAccount.accountNumber}
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={7} className="wallet-btns space-y-6">
                  {bankAccount.type === "USDT" ? (
                    <>
                      <Button
                        type="button"
                        className="border-0 color-bg-btn w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setShowUsdtModal(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Show
                      </Button>
                      {/* <Button
                        type="button"
                        className="btn-success w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setUpdateUsdtModal(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Edit
                      </Button> */}
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        className="border-0 color-bg-btn w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setShowBankModal(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Show
                      </Button>
                      {/* <Button
                        type="button"
                        className="btn-success w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setUpdateBankModal(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Edit
                      </Button> */}
                    </>
                  )}
                  <Button
                    type="button"
                    className="btn-danger w-lg"
                    onClick={() => {
                      setDeleteModal(true);
                      setSelectedBankId(bankAccount._id);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </CardWrapper>
          ))}
        </div>
        {/* <ZendeskChat /> */}
      </Container>
      { <><ShowDetails
        isOpen={showBankModal}
        toggleOpen={() => {
          setShowBankModal(!showBankModal);
        } }
        BankAccountData={selectedBank}
      ></ShowDetails><ShowUsdtMethodModal
        isOpen={showUsdtModal}
        toggleOpen={() => {
          setShowUsdtModal(!showUsdtModal);
        } }
        BankAccountData={selectedBank}
      ></ShowUsdtMethodModal></>}
      {/*<EditBankAccountModal
        isOpen={updateBankModal}
        toggleOpen={() => {
          setUpdateBankModal(false);
        }}
        BankAccountData={selectedBank}
      ></EditBankAccountModal>
      <EditUsdtMethodModal
        isOpen={updateUsdtModal}
        toggleOpen={() => {
          setUpdateUsdtModal(false);
        }}
        BankAccountData={selectedBank}
      ></EditUsdtMethodModal> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handelDelete}
        onCloseClick={() => setDeleteModal(!deleteModal)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.banks.loading,
  clientBankAccounts: state.bankAccountReducer.clientBankAccounts || [],
  error: state.banks.error,
});

export default connect(mapStateToProps, null)(withTranslation()(BankAccounts));