import * as axiosHelper from "./api_helper";
import qs from "qs";
export const getWithdrawals = async ({ payload })=>{
  const withdrawals = await axiosHelper.get(`/transactions/withdraw?${qs.stringify(payload)}`);
  return withdrawals;
};
export const addWithdrawal = async (values)=>{
  const endpoint = values?.walletType === "users" ? "/user-withdrawals" : "" ; 
  const result = await axiosHelper.post(`/transactions/withdraw${endpoint}`, values);

  if (result.isError){
    throw new Error(result.message || "Withdrawal has failed");
  }
  return result;
};

export const getUserTransactions = async ({ payload })=>{
  const transactions = await axiosHelper.get(`/transactions/withdraw/user-transactions?${qs.stringify(payload)}`);
  return transactions;
};

export const approveWithdrawal = async (payload)=>{
  const {id, customerId, walletType} = payload;
  const endpoint = walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${id}/approve${endpoint}`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  
  return result;
};
export const rejectWithdrawal = async (payload)=>{
  const {id, customerId, walletType} = payload;
  const endpoint = walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${id}/reject${endpoint}`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  
  return result;
};

export const getClientWithdrawals = async ({ payload }) => {
  const { clientId, type } = payload;
  const withdrawals = await axiosHelper.get(`/transactions/withdraw?customerId=${clientId}&type=${type}`);
  const data = {
    withdrawals: withdrawals
  };
  if (withdrawals.isError){
    throw new Error(data.isError);
  }

  return data;
};