import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Col,
} from "reactstrap";
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import { editUser, fetchUsers } from "store/users/actions";
import Loader from "components/Common/Loader";
import SearchableAccountTypes from "./SearchableAccountTypes";

function AddSalesStructure({ show, toggle, user, showPreview }) {
  const dispatch = useDispatch();
  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);

  useEffect(() => {
    if (user?.salesStructure?.length > 0) {
      const formattedTypes = user.salesStructure.map((item) => ({
        value: item.accountTypeId?._id || item.value, 
        label: item.accountTypeId?.title || item.label,
        salesCommission: item.salesCommission || 0,
        salesCommissionWithIB: item.salesCommissionWithIB || 0,
      }));
      setSelectedAccountTypes(formattedTypes);
    } else {
      setSelectedAccountTypes([]);
    }
  }, [user]);

  const handleChange = (selectedOptions) => {
    setSelectedAccountTypes(selectedOptions);
  };

  const handleAddStructure = (e, values) => {
    const salesStructure = selectedAccountTypes.map((accountType) => ({
      accountTypeId: accountType.value,
      salesCommission: parseFloat(values[`salesCommission_${accountType.value}`]) || 0,
      salesCommissionWithIB: parseFloat(values[`salesCommissionWithIB_${accountType.value}`]) || 0,
    }));

    dispatch(editUser({ 
      id: user._id, 
      values: { salesStructure } 
    }));
  };

  const { submitting, editSuccess } = useSelector((state) => state.usersReducer);

  useEffect(() => {
    if (editSuccess && show) {
      toggle(); 
      dispatch(fetchUsers({
        page: 1,
        limit: 100
      }));
    }
  }, [editSuccess]); 

  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {` ${showPreview ? "Preview" : "Add"} Sales Structure`}
      </ModalHeader>
      <ModalBody>
        <AvForm
          className="p-4"
          onValidSubmit={(e, v) => handleAddStructure(e, v)}
        >
          {!showPreview && (
            <SearchableAccountTypes
              isMulti={true}
              onChange={handleChange}
              value={selectedAccountTypes}
            />
          )}
          {selectedAccountTypes.length > 0 &&
            selectedAccountTypes.map((accountType, index) => (
              <div key={index}>
                <h5>{accountType.label}</h5>
                <Col md="12" className="d-flex justify-content-between align-items-center gap-2">
                  <div>
                    <AvField
                      name={`salesCommission_${accountType.value}`}
                      label="Sales Commission"
                      placeholder="Enter Sales Commission"
                      type="text"
                      defaultValue={accountType.salesCommission}
                      disabled={showPreview}
                    />
                  </div>
                  <div>
                    <AvField
                      name={`salesCommissionWithIB_${accountType.value}`}
                      label="Sales Commission With IB"
                      placeholder="Enter Sales Commission With IB"
                      type="text"
                      defaultValue={accountType.salesCommissionWithIB}
                      disabled={showPreview}
                    />
                  </div>
                </Col>
              </div>
            ))
          }

          {selectedAccountTypes.length === 0 && showPreview && (
            <h4 className="fw-bolder text-center">No Structures Found</h4>
          )}

          {!showPreview && (
            <div className="text-center">
              {submitting ? <Loader /> : (
                <Button type="submit" color="primary">
                  Add
                </Button>
              )}
            </div>
          )}

        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default AddSalesStructure;