/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";

import Loader from "components/Common/Loader";
// eslint-disable-next-line object-curly-newline
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Button,
  Label,
} from "reactstrap";
import TableLoader from "components/Common/Loader";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import WithDrawForm from "./WithDrawForm";
import {
  fetchUserTransactionStart,
  fetchWithdrawalsStart,
  makeWithdrawalStart,
  userWithdrawalUpdate,
  withdrawApproveStart,
  withdrawRejectStart,
} from "store/transactions/withdrawal/action";
import SearchBar from "components/Common/SearchBar";
// import Notification from "components/Common/Notification";
// import logo from "../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import CustomDropDown from "components/Common/CustomDropDown";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
// import Filter from "./DepositFilter";
import ReceiptModal from "./ReceiptModal";
import TransactionModal from "components/Common/TransactionModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUserTransactions } from "apis/withdrawal";
import moment from "moment";
import { addDepositStart, userDepositUpdate } from "store/transactions/deposit/action";
import DeleteModal from "components/Common/DeleteModal";

function Withdrawal(props) {
  const dispatch = useDispatch();
  const {
    t,
    loading,
    setLoading,
    transactions,
    customerId,
    totalTransactionDocs,
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  // const [showNotication, setShowNotifaction] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [selected, setSelected] = useState("LIVE");
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState({});
  const [rejectModal, setRejectModal] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    String(moment().subtract(30, "days").format("YYYY-MM-DD"))
  );
  const [dateTo, setDateTo] = useState(
    String(moment().add(1, "day").format("YYYY-MM-DD"))
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState(null);
  // const { userData: usrData } = useSelector((state) => ({
  //   userData: state.Profile,
  // }));

  // useEffect(() =>{
  //   const timerId = setTimeout(() => {
  //   }, 1000); 
  // }, []);
  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);

  const getAllTransactions = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getUserTransactions({
        payload: {
          limit: parseInt(limit),
          page: parseInt(page),
          filteredValues: {
            filterDate: {
              fromDate: dateFrom,
              toDate: dateTo,
            },
          },
          customerId,
        },
      });
      if (response.status) {
        setAllTransactions(response.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTransactions(1, 99999999).then(() => {
    });
  }, [dateFrom, dateTo]);

  const totalWithdrawalAmount = allTransactions?.docs?.filter((item) => item.type === "WITHDRAW" && item.status == "APPROVED").reduce(
    (acc, item) => acc + parseInt(item?.amount?.$numberDecimal || item.amount, 10),
    0
  );

  const totalDepositAmount = allTransactions?.docs
    ?.filter((item) => item.type === "DEPOSIT" && item.status == "APPROVED")
    .reduce(
      (acc, item) => acc + parseInt(item?.amount?.$numberDecimal || item.amount, 10),
      0
    );

  const pendingWithdrawalAmount = allTransactions?.docs?.filter((item) => item.type === "WITHDRAW" && item.status === "PENDING").reduce(
    (acc, item) => acc + parseInt(item?.amount?.$numberDecimal || item.amount, 10),
    0
  );

  const pendingDepositAmount = allTransactions?.docs
    ?.filter((item) => item.type === "DEPOSIT" && item.status === "PENDING")
    .reduce(
      (acc, item) => acc + parseInt(item?.amount?.$numberDecimal || item.amount, 10),
      0
    );

  const totalBalance = totalDepositAmount - totalWithdrawalAmount;
  const expectedBalance = totalBalance + (pendingDepositAmount - pendingWithdrawalAmount);

  const loadTabs = () => [
    {
      tabId: "customers",
      navLinkName: "Deposits",
      component: <Withdrawal walletType="customers" />,
    },
    {
      tabId: "users",
      navLinkName: "Withdrawals",
      component: <Withdrawal walletType="users" />,
    },
  ];

  const tabs = loadTabs().filter((item) => !item.hidden);
  const [walletType, setalletType] = useState("users");

  const toggle = (tab) => {
    if (walletType !== tab) {
      setalletType(tab);
    }
  };

  const revokeCommission = () => {
    if (selectedCommission.type === "DEPOSIT") {
      dispatch(makeWithdrawalStart({
        walletType: "users",
        customerId: selectedCommission.customerId._id,
        walletId: selectedCommission.walletId,
        gateway: "REFUND",
        note: `Undo for Deposit (${selectedCommission.recordId})`,
        amount: selectedCommission.amount.$numberDecimal,
        isAutoApprove: true,
      }));
      dispatch(userWithdrawalUpdate({
        id: selectedCommission._id,
        revoked: true,
      }));
    } else {
      dispatch(addDepositStart({
        walletType: "users",
        customerId: selectedCommission.customerId._id,
        walletId: selectedCommission.walletId,
        gateway: "REFUND",
        note: `Undo for Withdrawal (${selectedCommission.recordId})`,
        amount: selectedCommission.amount.$numberDecimal,
      }));
      dispatch(userDepositUpdate({
        id: selectedCommission._id,
        revoked: true,
      }));
    }
    setDeleteModal(false);
  };

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-withdrawals"
    //       onChange={() =>
    //         checkAllBoxes("check-all-withdrawals", ".withdraw-checkbox")
    //       }
    //     />
    //   ),
    // },
    // {
    //   dataField: "recordId",
    //   text: props.t("Transaction Id"),
    // },
    // {
    //   dataField: "amount",
    //   text: props.t("Amount"),
    //   formatter: (val) => val?.amount?.$numberDecimal || val?.amount || "",
    // },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: val.customerId
                  ? `/clients/${val.customerId._id}/profile`
                  : "",
                state: { clientId: val.customerId ? val.customerId._id : "" },
              }}
            >
              <i className="no-italics">
                {" "}
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val.customerId.firstName
                    )} ${captilazeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (val) => {
        const amount = val?.amount?.$numberDecimal || val?.amount || "";
        const isDeposit = val?.type === "DEPOSIT"; // Adjust this condition based on your data structure
        const color = isDeposit ? "green" : "red";
        return <span style={{ color }}>{amount}</span>;
      },
    },
    {
      dataField: "type",
      text: props.t("Type"),
      formatter: (val) => val.type === "DEPOSIT" ? "COMMISSION" : val.type,
    },
    {
      dataField: "reason",
      text: "Description",
      formatter: (val) =>
        val.reason ? (
          <div data-title={val.reason}>
            {val.reason.length > 20
              ? `${val.reason.slice(0, 20)}...`
              : val.reason}
          </div>
        ) : (
          <>{val?.note ?? " - "}</>
        ),
    },
    {
      dataField: "createdAt",
      text: props.t("Operation Time"),
      formatter: (val) => formatDate(val.createdAt),
    },
    // {
    //   dataField: "",
    //   text: "Settings",
    //   formatter: (val) => <Button onClick={() => {
    //     setSelectedCommission(val);
    //     setDeleteModal(true);
    //   }}>Undo</Button>,
    // },
    // {
    //   dataField: "processedBy",
    //   text: props.t("Processed By"),
    //   formatter: (val) => {
    //     return (
    //       <div>
    //         <span className="no-italics" style={{ fontWeight: "bold" }}>
    //           {val.processedBy
    //             ? `${captilazeFirstLetter(
    //                 val.processedBy.firstName
    //               )} ${captilazeFirstLetter(val.processedBy.lastName)}`
    //             : val.gateway === "ASIA_BANKS" ? "By System" : ""}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "gateway",
    //   text: props.t("Gateway"),
    //   formatter: (val) =>
    //     val.gateway === "WIRE_TRANSFER"
    //       ? "Wire"
    //       : val.gateway?.split("_").join(" ")?.toLowerCase(),
    // },

    // {
    //   dataField: "convertedFromAmount",
    //   text: props.t("From Amount"),
    //   formatter: (val) =>
    //     val?.convertedFromAmount?.$numberDecimal ||
    //     val?.convertedFromAmount ||
    //     "",
    // },
    // {
    //   dataField: "fromCurrency",
    //   text: props.t("From Currency"),
    // },
    // {
    //   dataField: "currency",
    //   text: props.t("Currency"),
    // },

    // walletType === "users" &&
    // {
    //   dataField: "fromCurrency",
    //   text: props.t("From Currency"),
    // },
    // {
    //   dataField: "currency",
    //   text: props.t("Currency"),
    // },
    // walletType === "users" &&
    // {
    //   dataField: "convertedFromAmount",
    //   text: props.t("From Amount"),
    //   formatter: (val) => val?.convertedFromAmount?.$numberDecimal || val?.convertedFromAmount || "",
    // },
    // {
    //   dataField: "amount",
    //   text: props.t("Amount"),
    //   formatter: (val) => val?.amount?.$numberDecimal || val?.amount || "",
    // },
    // {
    //   dataField: "status",
    //   text: props.t("Status"),
    //   formatter: (item) => <Badge status={item.status}></Badge>,
    // },
    // {
    //   dataField: "dropdown",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Actions"),
    // },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Details"),
    //   formatter: (val) => (
    //     <div className="text-center">
    //       <Link
    //         className={`${
    //           ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER"].includes(val.gateway)
    //             ? "text-success"
    //             : "text-muted"
    //         }`}
    //         to="#"
    //       >
    //         <i
    //           className="mdi mdi-eye font-size-20"
    //           id="edittooltip"
    //           onClick={() => {
    //             if (val.gateway === "BLOCKCHAIN") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 network:
    //                   `${val?.rawData?.data?.item?.network} ${val?.rawData?.data?.item?.blockchain}` ||
    //                   "-",
    //                 referenceId: val?.rawData?.referenceId || "-",
    //                 amount: val?.amount?.$numberDecimal || val?.amount || "-",
    //                 address: val?.rawData?.data?.item?.address || "-",
    //                 confirmations:
    //                   val?.rawData?.data?.item?.currentConfirmations || "-",
    //                 requiredConfirmations:
    //                   val?.rawData?.data?.item?.targetConfirmations || "-",
    //                 transactionId:
    //                   val?.rawData?.data?.item?.transactionId || "-",
    //               });
    //             } else if (val.gateway == "WIRE_TRANSFER") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             } else if (val.gateway == "CRYPTO") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             }
    //           }}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    gateway: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  const loadWithdrawals = (page, limit) => {
    console.log("TO: ", dateTo);
    console.log("FROM: ", dateFrom);
    if (searchInput !== "" && searchInput.length >= 3) {
      dispatch(
        fetchUserTransactionStart({
          page,
          limit,
          type: selected,
          searchText: searchInput,
          filteredValues: {
            filterDate: {
              fromDate: dateFrom,
              toDate: dateTo,
            },
          },
          customerId: props.customerId,
        })
      );
    } else if (searchInput === "") {
      console.log("clicked");
      dispatch(
        fetchUserTransactionStart({
          page,
          limit,
          type: selected,
          filteredValues,
          customerId: props.customerId,
        })
      );
    }
  };
  const withdrawApprove = (withdraw) => {
    dispatch(
      withdrawApproveStart({
        id: withdraw._id,
        customerId: withdraw.customerId._id,
        amount: withdraw.amount,
        note: withdraw.note,
        walletType,
      })
    );
    setApproveModal(false);
  };
  const withdrawReject = (withdraw) => {
    dispatch(
      withdrawRejectStart({
        id: withdraw._id,
        customerId: withdraw.customerId._id,
        note: withdraw.note,
        walletType,
      })
    );
    setRejectModal(false);
  };

  useEffect(() => {
    loadWithdrawals(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    searchInput,
    selected,
    props.withdrawResponseMessage,
    props.withdrawChangeStatusSuccess,
    filteredValues,
    walletType,
  ]);

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            {/* <Nav tabs>
              {tabs.map((tabItem) => (
                <>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: walletType === tabItem.tabId,
                      })}
                      onClick={() => {
                        toggle(tabItem.tabId);
                      }}
                    >
                      {tabItem.navLinkName}
                    </NavLink>
                  </NavItem>
                </>
              ))}
            </Nav> */}

            <CardHeader className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(`Transactions(${allTransactions?.docs?.length})`)}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadWithdrawals(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <SearchBar
                  className="mt-1"
                  handleSearchInput={handleSearchInput}
                  placeholder={props.t("Enter Transaction Id")}
                />
                <AvForm
                  className="d-flex flex-row align-items-center"
                  onValidSubmit={(e, data) => {
                    console.log("FILTERING");
                    loadWithdrawals(1, sizePerPage);
                  }}
                >
                  <div className="align-items-center px-2">
                    {/* <Col xs="12" md="3" lg="2">
              <Label>{t("Platform")}</Label>
              <AvFieldSelect
                className="mt-1 form-select" 
                name="platform"
                errorMessage={t("Platform is required")}
                validate={{ required: { value: true } }}
                options={platformOptions}
                onChange={(e) => setSelectedPlatform(e)}
              />
            </Col> */}
                    <div className="d-flex justify-content-center align-items-center gap-3">
                      <AvField
                        className="mt-1 mb-2"
                        type="date"
                        name="dateFrom"
                        label={t("From Date")}
                        value={dateFrom}
                        validate={{ date: { format: "YYYY-MM-DD" } }}
                        onChange={(e) => {
                          console.log("DATE FROM: ", e.target.value);
                          handleDateFrom(e);
                        }}
                      />
                      <AvField
                        className="mt-1 mb-2"
                        type="date"
                        name="dateTo"
                        label={t("To Date")}
                        validate={{ date: { format: "YYYY-MM-DD" } }}
                        value={dateTo}
                        onChange={handleDateTo}
                      />
                    </div>
                  </div>
                  <div className="mt-1 py-6 w-100 d-flex justify-content-center">
                    <Button
                      color="primary"
                      className="btn-light "
                      disabled={loading}
                    >
                      {loading ? <Loader /> : t("Search")}
                    </Button>
                  </div>
                </AvForm>

                {/* <div >
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        {selected} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem value="LIVE" onClick={(e)=>{setSelected(e.target.value)}}>LIVE</DropdownItem>
                        <DropdownItem value="DEMO" onClick={(e)=>{setSelected(e.target.value)}}>DEMO</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                {/* <div className="d-flex flex-row align-items-center justify-content-between"> */}
                {/* <WithDrawForm /> */}
                {/* </div> */}
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5">
                <Label>
                  <span style={{
                    color: "#00b300",
                    fontWeight: "bold"
                  }}>
                  Commission:
                  </span>
                  <span
                    style={{
                      color: "#00b300",
                      fontWeight: "bold",
                    }}
                  >
                    {` ${parseInt(totalDepositAmount) || 0}`}
                  </span>
                </Label>
                <Label>
                  <span style={{
                    color: "#b71c1c",
                    fontWeight: "bold"
                  }}>
                  Withdraw:
                  </span>
                  <span
                    style={{
                      color: "#b71c1c",
                      fontWeight: "bold",
                    }}
                  >
                    {` ${parseInt(totalWithdrawalAmount) || 0}`}
                  </span>
                </Label>
                <Label>
                  <span style={{
                    color: "#1976d2",
                    fontWeight: "bold"
                  }}>Balance:</span>
                  <span
                    style={{
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {` ${parseInt(totalBalance) || 0}`}
                  </span>
                </Label>
                <Label>
                  <span style={{
                    color: "#ff8c00",
                    fontWeight: "bold"
                  }}>ExpectedBalance:</span>
                  <span
                    style={{
                      color: "#ff8c00",
                      fontWeight: "bold",
                    }}
                  >
                    {` ${parseInt(expectedBalance) || 0}`}
                  </span>
                </Label>
              </div>
            </CardHeader>

            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) => (
                          <Th data-priority={index} key={index}>
                            <span className="color-primary">{column.text}</span>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    {props.loading && (
                      <Tr className="w-100 d-flex">
                        <TableLoader colSpan={4} />
                      </Tr>
                    )}
                    {allTransactions.length === 0 ? (
                      <Tbody>
                        {!props.loading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {!props.loading &&
                          allTransactions.docs.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="withdraw-checkbox"
                                      type="checkbox"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {/* {column.dataField === "dropdown" && (
                                    <CustomDropDown
                                      permission={
                                        props.withdrawalsPermissions.actions
                                      }
                                      status={row.status}
                                      approve={() => {
                                        setApproveModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                      reject={() =>{
                                        setRejectModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                    />
                                  )} */}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadWithdrawals}
                    docs={props.withdrawals}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ReceiptModal
        open={detailsModal}
        content={{
          type: "json",
          content: selectedContent,
        }}
        onClose={() => setDetailsModal(false)}
      />
      {approveModal && (
        <TransactionModal
          show={approveModal}
          title="Approve Withdraw"
          submitButton="Approve"
          showAmount={true}
          toggle={() => {
            setApproveModal(!approveModal);
          }}
          t={props.t}
          onSubmit={withdrawApprove}
          data={selectedWithdraw}
        ></TransactionModal>
      )}
      {rejectModal && (
        <TransactionModal
          show={rejectModal}
          title="Reject Withdraw"
          submitButton="Reject"
          showAmount={false}
          toggle={() => {
            setRejectModal(!rejectModal);
          }}
          t={props.t}
          onSubmit={withdrawReject}
          data={selectedWithdraw}
        ></TransactionModal>
      )}
      {<DeleteModal loading={props.deleteLoading} onDeleteClick={revokeCommission} show={deleteModal} onCloseClick={() => setDeleteModal(false)} revoke={true} />}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.withdrawalReducer?.userTransactions?.loading || false,
  withdrawals: state.withdrawalReducer?.userTransactions?.withdrawals || [],
  page: state.withdrawalReducer?.userTransactions?.page || 1,
  totalDocs: state.withdrawalReducer?.userTransactions?.totalDocs || 0,
  totalPages: state.withdrawalReducer?.userTransactions?.totalPages || 0,
  hasNextPage: state.withdrawalReducer?.userTransactions?.hasNextPage,
  hasPrevPage: state.withdrawalReducer?.userTransactions?.hasPrevPage,
  limit: state.withdrawalReducer?.userTransactions?.limit,
  nextPage: state.withdrawalReducer?.userTransactions?.nextPage,
  pagingCounter: state.withdrawalReducer?.userTransactions?.pagingCounter,
  prevPage: state.withdrawalReducer?.userTransactions?.prevPage,
  withdrawalsPermissions: state.Profile.withdrawalsPermissions || {},
  withdrawResponseMessage:
    state.withdrawalReducer?.userTransactions?.withdrawResponseMessage,
  withdrawChangeStatusSuccess:
    state.withdrawalReducer?.userTransactions?.withdrawChangeStatusSuccess,
});
export default connect(mapStateToProps, null)(withTranslation()(Withdrawal));