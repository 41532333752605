import React, { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import * as accountsApi from "apis/tradingAccounts";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { fetchAccountTypes } from "store/actions";


const SearchableAccountTypes = (props) => {

  const dispatch = useDispatch();

  const {
    placeholder = "Search",
    isRequired = false,
    name = "accountTypes",
    defaultOptions = [],
    value = null,
    accountTypes = [],
    onChange = () => { },
    isMulti = false,
  } = props;

  const [accountTypesOptions, setAccountTypesOptions] = React.useState(defaultOptions);

  useEffect(() => {
    setAccountTypesOptions(accountTypes.map((accountType) => ({
      value: accountType._id,
      label: accountType.title
    })));
  }, []);

  useEffect(() => {
    dispatch(fetchAccountTypes({
      limit: 1000,
      page: 1
    }));
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      accountsApi.getAccountTypes({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10,
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((accountType) => (
          {
            label : accountType.title,
            value : accountType._id
          }
        )));
      });
    }, 1000), []);

  return (
    <AsyncAvFieldSelect
      name={name}
      options={accountTypesOptions}
      label={props.t("Account Type")}
      errorMessage={props.t("Account Type is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={accountTypesOptions || defaultOptions}
      value={value.map((val) => ({
        label: val?.label,
        value: val?.value,
      }))}
      defaultValue={value.map((val) => ({
        label: val?.label,
        value: val?.value,
      }))}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
    />
  );
};

const mapStateToProps = (state) => ({
  accountTypes: state.tradingAccountReducer.accountTypes,
  accountTypesLoading: state.tradingAccountReducer.accountTypesLoading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableAccountTypes));
